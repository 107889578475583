<template>
  <div style="margin:50px auto;display:block;">



      <p style="padding:25px 0px 25px 0px;font-size:30px;color:#333;font-weight:600;text-align:center">
          Solicita un Inmueble
      </p>
      <div style="margin:50px auto;display:block;width:50px;border-top:solid 4px #D41700"></div>

      <v-card flat style="width:80%;margin:50px auto;display:block;border-radius:10px;">
          <v-card-text style="margin:0 auto;display:block;width:90%;padding:50px 0px 20px 0px;">
              <v-layout row justify-center>
                  <v-flex xs12 md6>
                  <div style="width:90%;margin:0 auto;display:block;">
                      <label>
                      ¿Venta o Alquiler?
                        <v-select @change="tipoBusqueda($event)"
                        outlined background-color="#fff" style="color:#000;width:90%"
                    :items="TipoDeBusqueda" dense
                    ></v-select>
                    </label>
                    <label>Estacionamiento <br>
                          <input type="text"
                          v-model="pr_puestos"
                           placeholder="Estacionamiento" class="inputTxts">
                      </label>

                      <br><br>
                      <label>
                      Estado
                        <v-select outlined background-color="#fff" style="color:#000;width:90%"
                    :items="Estados" dense
                    @change="onChangeEstado($event)"
                    ></v-select></label>

                      <label>
                      Ciudade
                        <v-select outlined background-color="#fff" 
                        @change="ciudades($event)"
                        style="color:#000;width:90%"
                    :items="Ciudades" dense
                    ></v-select>
                    </label>
                  </div>
                    
                  </v-flex>



                  <v-flex xs12 md6>
                     <div style="width:90%;margin:0 auto;display:block;">
                          <label>
                      Tipo de Inmueble
                        <v-select outlined background-color="#fff" style="color:#000;width:90%"
                    :items="Inmuebles" dense
                    @change="onChangeInmueble($event)"
                    ></v-select>
                    </label>
                      <label>Habitaciones
                          <input type="text" v-model="pr_habitaciones"
                          placeholder="Habitaciones" class="inputTxts">
                      </label><br><br>
                      <label>Baños <br>
                          <input type="text" placeholder="Baños" 
                          v-model="pr_banos"
                          class="inputTxts">
                      </label> <br><br>
                      <label>Urbanización <br>
                          <input type="text" v-model="pr_sector"
                          placeholder="Urbanización" class="inputTxts">
                      </label>
                     </div>
                    
                      
                  </v-flex>
              </v-layout>


                <v-divider class="my-5"></v-divider>


              <v-layout row justify-center style="margin-top:50px;">
                  <v-flex xs12 md3>
                      <div style="width:90%;margin:0 auto;display:block;">
                          <label>Nombre & Apellido</label>
                          <br>
                          <input type="text" placeholder="" v-model="username"
                          style="width:100%;outline:0;padding:10px;border-radius:5px;background:white;
                          color:black;border:solid 1px #ccc"
                          >
                      </div>
                  </v-flex>
                  <v-flex xs12 md3>
                      <div style="width:90%;margin:0 auto;display:block;">
                          <label>C.I / RIF</label>
                          <br>
                          <input type="text" placeholder="" v-model="dni"
                          style="width:100%;outline:0;padding:10px;border-radius:5px;background:white;
                          color:black;border:solid 1px #ccc"
                          >
                      </div>
                  </v-flex>
                  <v-flex xs12 md3>
                      <div style="width:90%;margin:0 auto;display:block;">
                          <label>Teléfono</label>
                          <br>
                          <input type="text" placeholder="" v-model="tlf"
                          style="width:100%;outline:0;padding:10px;border-radius:5px;background:white;
                          color:black;border:solid 1px #ccc"
                          >
                      </div>
                  </v-flex>
                  <v-flex xs12 md3>
                      <div style="width:90%;margin:0 auto;display:block;">
                          <label>Correo Electrónico</label><br>
                          <input type="text" placeholder="" v-model="email"
                          style="width:100%;outline:0;padding:10px;border-radius:5px;background:white;
                          color:black;border:solid 1px #ccc"
                          >
                      </div>
                  </v-flex>
              </v-layout>
          </v-card-text>
          <v-card-text>
              <v-btn 
                    @click="enviarCorreo()"
                  style="margin:25px auto;display:block;color:white;background:#D41700;border-radius:10px;" text>
                  Enviar
                  </v-btn>
          </v-card-text>
      </v-card>



  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            Ciudades:[],username:'',tlf:'',email:'',dni:'',
            pr_puestos:'',pr_sector:'',pr_estado:'',pr_categoria:'',pr_ciudad:'',pr_habitaciones:'',pr_banos:'',
            Inmuebles:['FONDO DE COMERCIO', 'POSADAS', 'APARTAMENTOS','PENTHOUSE','TOWNHOUSE','CASA','TERRENO','FINCAS','EDIFICIOS','GALPON','LOCAL','OFICINA'],
            TipoDeBusqueda:[
                'SELECCIONAR', 'ALQUILER', 'VENTA', 'VENTA / ALQUILER'
            ],
            Estados:[
                'SELECCIONAR',
                'AMAZONAS',
                'ANZOÁTEGUI',
                'APURE',
                'ARAGUA',
                'BARINAS',
                'BOLÍVAR',
                'CARABOBO',
                'COJEDES',
                'DELTA AMACURO',
                'DISTRITO CAPITAL',
                'FALCÓN',
                'GUÁRICO',
                'LARA',
                'MÉRIDA',
                'MIRANDA',
                'MONAGAS',
                'NUEVA ESPARTA',
                'PORTUGUESA',
                'SUCRE',
                'TÁCHIRA',
                'TRUJILLO',
                'VARGAS',
                'YARACUY',
                'ZULIA'
            ],
        }
    },
    methods:{
        async enviarCorreo(){
            try {
                await axios.post('https://inmoglobalve.com.ve/Main.php', {
                    username: this.username,
                    dni: this.dni,
                    email: this.email,
                    tlf: this.tlf,
                    pr_tipo: this.pr_tipo,
                    pr_habitaciones: this.pr_habitaciones,
                    pr_puestos: this.pr_puestos,
                    pr_banos: this.pr_banos,
                    pr_sector: this.pr_sector,
                    pr_estado: this.pr_estado,
                    pr_ciudad: this.pr_ciudad,
                    pr_categoria: this.pr_categoria
                }).then(()=>{
                    alert('Se ha enviado correctamente!')
                })
            } catch (error) {
                console.log(error)
            }
        },
        tipoBusqueda(event){
            this.pr_tipo = event
        },
        onChangeInmueble(event){
            this.pr_categoria = event
        },
        ciudades(event){
            this.pr_ciudad = event
        },
         onChangeEstado(e){
            let ests = e
        if (ests == 'AMAZONAS') {
            this.Ciudades = [
                'SELECCIONAR',
                'MAROA',
                'PUERTO AYACUCHO',
                'SAN FERNANDO DE ATABAPO'
            ]
            this.pr_estado = 'AMAZONAS'
            
        }
        if (ests == 'ANZOÁTEGUI') {
            this.Ciudades = [
                'SELECCIONAR',
                'ANACO',
                'ARAGUA DE BARCELONA',
                'BARCELONA',
                'BOCA DE UCHIRE',
                'CANTAURA',
                'CLARINES',
                'EL CHAPARRO',
                'EL PAO ANZOATEGUI',
                'EL TIGRE',
                'EL TIGRITO',
                'GUANAPE',
                'GUANTA',
                'LECHERIA',
                'ONOTO',
                'PARIAGUAN',
                'PIRITU',
                'PUERTO LA CRUZ',
                'PUERTO PIRITU',
                'SABANA DE UCHIRE',
                'SAN MATEO ANZOATEGUI',
                'SAN PABLO ANZOATEGUI',
                'SANTA ANA DE ANZOATEGUI',
                'SANTA FE ANZOATEGUI',
                'SANTA ROSA',
                'SAN TOME',
                'SOLEDAD',
                'URICA',
                'VALLE DE GUANAPE'
            ]
            this.pr_estado = 'AMAZONAS'
            
        }
        if (ests == 'APURE') {
            this.Ciudades = [
                'SELECCIONAR',
                'ACHAGUAS',
                'BIRUACA',
                'BRUZUAL',
                'AL AMPARO',
                'EL NULA',
                'ELORZA',
                'EL SAMAN DE APURE',
                'GUASDUALITO',
                'MANTECAL',
                'PUERTO PAEZ',
                'SAN FERNANDO DE APURE',
                'SAN JUAN DE PAYARA'
            ]
            this.pr_estado = 'APURE'
            
        }
        if (ests == 'ARAGUA') {
            this.Ciudades = [
                'SELECCIONAR',
                'BARBACOAS',
                'CAGUA',
                'CAMATAGUA',
                'CHORONI',
                'COLONIA TOVAR',
                'EL CONSEJO',
                'LAS TEJERIAS',
                'LA VICTORIA',
                'MAGDALENO',
                'MARACAY',
                'OCUMARE DE LA COSTA',
                'PALO NEGRO',
                'SAN CASIMIRO',
                'SAN MATEO',
                'SAN SEBASTIAN',
                'SANTA CRUZ DE ARAGUA',
                'TOCORON',
                'TURMERO',
                'VILLA DE CURA',
                'ZUATA'
            ]
            this.pr_estado = 'ARAGUA'
            
        }
        if (ests == 'BARINAS') {
            this.Ciudades = [
                'SELECCIONAR',
                'ALTAMIRA',
                'ARISMENDI',
                'BARINAS',
                'BARINITAS',
                'CALDERAS',
                'CAPITANEJO',
                'CIUDAD BOLIVIA',
                'CIUDAD DE NUTRIAS',
                'DOLORES',
                'EL CANTON',
                'GUADARRAMA',
                'LAS VEGUITAS',
                'LA UNION',
                'LIBERTAD DE BARINAS',
                'OBISPOS',
                'QUEBRADA SECA',
                'SABANETA',
                'SAN SILVESTRE',
                'SANTA BARBARA DE BARINAS',
                'SANTA ROSA',
                'SOCOPO'                
            ]
            this.pr_estado = 'BARINAS'
            
        }
        if (ests == 'BOLÍVAR') {
            this.Ciudades = [
                'SELECCIONAR',
                'CAICARA DEL ORINOCO',
                'CIUDAD BOLIVAR',
                'CIUDAD PIAR',
                'EL CALLAO',
                'EL DORADO',
                'EL MANTECO',
                'EL PALMAR',
                'EL PAO',
                'GUASIPATI',
                'LA PARAGUA',
                'MARIPA',
                'MATANZAS',
                'PUERTO ORDAZ',
                'SAN FELIX',
                'SANTA ELENA DE UAIREN',
                'SOLEDAD',
                'TUMEREMO',
                'UPATA'
                
            ]
            this.pr_estado = 'BOLÍVAR'
            
        }
        if (ests == 'CARABOBO') {
            this.Ciudades = [
                'SELECCIONAR',
                'BEJUMA',
                'BELEN',
                'BOCA DE AROA',
                'CANOABO',
                'CENTRAL TACARIGUA',
                'CHICHIRIVICHE',
                'CHIRGUA',
                'GUACARA',
                'GUIGUE',
                'LOS GUAYOS',
                'MARIARA',
                'MIRANDA',
                'MONTALBAN',
                'MORON',
                'NAGUANAGUA',
                'PUERTO CABELLO',
                'SAN DIEGO',
                'SAN JOAQUIN',
                'TOCUYITO',
                'TOCUYO DE LA COSTA',
                'TUCACAS',
                'URAMA',
                'VALENCIA'               
            ]
            this.pr_estado = 'CARABOBO'
            
        }
        if (ests == 'COJEDES') {
            this.Ciudades = [
                'SELECCIONAR',
                'APARTADEROS COJEDES',
                'ARISMENDI',
                'EL BAUL',
                'EL PAO COJEDES',
                'EL TORITO',
                'LA AGUADITA',
                'LAS VEGAS',
                'LIBERTAD DE COJEDES',
                'MACAPO',
                'MANRIQUE',
                'SAN CARLOS',
                'SUCRE',
                'TINACO',
                'TINAQUILLO',
                
            ]
            this.pr_estado = 'COJEDES'
            
        }
        if (ests == 'DELTA AMACURO') {
            this.Ciudades = [
                'SELECCIONAR',
                'TUCUPITA'                
            ]
            this.pr_estado = 'DELTA AMACURO'
            
        }
        if (ests == 'DISTRITO CAPITAL') {
            this.Ciudades = [
                'SELECCIONAR',
                'CARACAS'                
            ]
            this.pr_estado = 'DISTRITO CAPITAL'
            
        }
        if (ests == 'FALCÓN') {
            this.Ciudades = [
                'SELECCIONAR',
                'ADAURE',
                'ADICORA',
                'AGUA LINDA',
                'BOCA DE AROA',
                'CABURE',
                'CAPADARE',
                'CAPATARIDA',
                'CHICHIRIVICHE',
                'CHURUGUARA',
                'CORO',
                'DABAJURO',
                'JUDIBANA',
                'LA CRUZ DE TARATARA',
                'LA VELA DE CORO',
                'LOS TAQUES',
                'MAPARARI',
                'MENE DE MAUROA',
                'MIRIMIRE',
                'PIEDRA GRANDE',
                'PIRITU FALCON',
                'PUEBLO NUEVO FALCON',
                'PUERTO CARDON',
                'PUNTO FIJO',
                'SAN JUAN DE LOS CAYOS',
                'SAN LUIS',
                'SANTA CRUZ DE BUCARAL',
                'TOCOPERO',
                'TOCUYO DE LA COSTA',
                'TUCACAS',
                'YARACAL'
                
            ]
            this.pr_estado = 'FALCÓN'
            
        }
        if (ests == 'GUÁRICO') {
            this.Ciudades = [
                'SELECCIONAR',
                'ALTAGRACIA DE ORITUCO',
                'BARBACOA',
                'CABRUTA',
                'CALABOZO',
                'CAMAGUAN',
                'CAMATAGUA',
                'CARMEN DE CURA',
                'CHAGUARAMAS GUARICO',
                'EL CALVARIO',
                'EL RASTRO',
                'EL SOCORRO',
                'EL RASTRO',
                'EL SOCORRO',
                'EL SOMBRERO',
                'GUARDATINAJA',
                'LAS MERCEDES DE LOS LLANOS',
                'LEZAMA',
                'ORTIZ',
                'SAN CASIMIRO',
                'SAN JOSE DE GUARIBE',
                'SAN JOSE UNARE',
                'SAN JUAN DE LOS MORROS',
                'SAN RAFAEL DE LAYA',
                'SAN SEBASTIAN',
                'SANTA MARIA DE IPIRE',
                'TUCUPIDO',
                'VALLE DE LA PASCUA',
                'ZARAZA'
                
            ]
            this.pr_estado = 'GUÁRICO'
            
        }
        if (ests == 'LARA') {
            this.Ciudades = [
                'SELECCIONAR',
                'AGUADA GRANDE',
                'ALTAGRACIA',
                'AREGUE',
                'ATARIGUA',
                'BARAGUA',
                'BARQUISIMETO',
                'BOBARE',
                'BUENA VISTA',
                'CABUDARE',
                'CARIRAGUA',
                'CARORA',
                'CUBIRO',
                'DUACA',
                'EL EMPEDRADO',
                'EL TOCUYO',
                'GUARICO',
                'GUAYABONES',
                'HUMOCARO ALTO',
                'HUMOCARO BAJO',
                'LA ESCALERA',
                'LA MIEL',
                'MOYETONES',
                'PIE DE CUESTA',
                'QUIBOR',
                'RIO BLANCO',
                'RIO TOCUYO',
                'SANARE',
                'SAN FRANCISCO',
                'SAN PEDRO',
                'SANTA INES',
                'SARARE',
                'VILLA NUEVA'
                
            ]
            this.pr_estado = 'LARA'
            
        }
        if (ests == 'MÉRIDA') {
            this.Ciudades = [
                'SELECCIONAR',
                'ACEQUIAS',
                'ARAPUEY',
                'BAILADORES',
                'CANAGUA',
                'CAPAZON',
                'CHACHOPO',
                'CHIGUARA',
                'CHOCANTA',
                'EJIDO',
                'EL VIGIA',
                'JAJI',
                'LA AZULITA',
                'LAGUNILLAS MERIDA',
                'LA PLAYA',
                'LAS VIRTUDES',
                'MERIDA',
                'MESA DE BOLIVAR',
                'MESA DE QUINTERO',
                'MUCUCHIES',
                'MUCUJEPE',
                'MUCURUBA',
                'MUCUTUY',
                'NUEVA BOLIVIA',
                'PALMARITO',
                'PIÑANGO',
                'PUEBLO LLANO',
                'PUEBLO NUEVO',
                'SAN CRISTONBAL DE TORONDOY',
                'SANTA APOLONIA DE MERIDA',
                'SANTA CRUZ DE MORA',
                'SANTA ELENA DE ARENALES',
                'SANTO DOMINGO',
                'TABAY',
                'TIMONES',
                'TORONDOY',
                'TOVAR',
                'TUCANI',
                'ZEA'
                
            ]
            this.pr_estado = 'MÉRIDA'
            
        }
        if (ests == 'MIRANDA') {
            this.Ciudades = [
                'SELECCIONAR',
                'ARAGUITA',
                'CAPAYA',
                'CARRIZAL',
                'CAUCAGUA',
                'CHACAO',
                'CHARALLAVE',
                'CUA',
                'CUPIRA',
                'CURIEPE',
                'EL GUAPO',
                'EL JARILLO',
                'FILAS DE MARICHE',
                'GUARENAS',
                'GUATIRE',
                'HIGUEROTE',
                'LOS TEQUES',
                'MACHURUCUTO',
                'OCUMARE DEL TUY',
                'PANAQUIRE',
                'PARACOTOS',
                'RIO CHICO',
                'SAN ANTONIO DE LOS ALTOS',
                'SAN FRANCISCO DE YARE',
                'SAN JOSE DE BARLOVENTO',
                'SANTA LUCIA',
                'SANTA TERESA',
                'TACARIGUA DE LA LAGUNA',
                'TACARIGUA DE MAMPORAL',
                'TACATA'
            ]
            this.pr_estado = 'MIRANDA'
            
        }
        if (ests == 'MONAGAS') {
            this.Ciudades = [
                'SELECCIONAR',
                'AGUASAY',
                'ARAGUA DE MATURIN',
                'BARRANCAS DEL ORINOCO',
                'CAICARA DE MATURIN',
                'CARIPE',
                'CARIPITO',
                'CHAGUARAMAL',
                'CHAGUARAMAS MONAGAS',
                'EL FURRIAL',
                'EL TEJERO',
                'GUANAGUANA',
                'JUSEPIN',
                'LA TOSCANA',
                'MATURIN',
                'MIRAFLORES',
                'PUNTA DE MATA',
                'QUIRIQUIRE',
                'SAN ANTONIO',
                'SAN ANTONIO DE TABASCA',
                'SAN FELIX DE MONAGAS',
                'SANTA BARBARA',
                'SAN VICENTE',
                'TEMBLADOR',
                'TERESEN',
                'URACOA',
                'VIENTO FRESCO'
                
            ]
            this.pr_estado = 'MONAGAS'
            
        }
        if (ests == 'NUEVA ESPARTA') {
            this.Ciudades = [
                'SELECCIONAR',
                'ALTAGRACIA',
                'BOCA DE POZO',
                'BOCA DE RIO',
                'EL VALLE DEL ESPIRITU SANTO',
                'JUANGRIEGO',
                'LA ASUNCION',
                'LA GUARDIA',
                'PAMPATAR',
                'PORLAMAR',
                'PUNTA DE PIEDRAS',
                'SAN JUAN BAUTISTA',
                'SAN PEDRO DE COCHE',
                'SANTA ANA DE NUEVA ESPARTA',
                'VILLA ROSA'
            ]
            this.pr_estado = 'NUEVA ESPARTA'
            
        }
        if (ests == 'PORTUGUESA') {
            this.Ciudades = [
                'SELECCIONAR',
                'ACARIGUA',
                'AGUA BLANCA',
                'ARAURE',
                'BISCUCUY',
                'BOCONOITO',
                'CHABASQUEN',
                'COLONIA TUNEN',
                'GUANARE',
                'GUANARITO',
                'LA MISION',
                'MESA DE CAVACAS',
                'OSPINO',
                'PAPELON',
                'PAYARA',
                'PIMPINELA',
                'PIRITU DE PORTUGUESA',
                'SAN RAFAEL DE ONOTO',
                'SANTA ROSALIA DE TUREN',
                'TUREN'                
            ]
            this.pr_estado = 'PORTUGUESA'
            
        }
        if (ests == 'SUCRE') {
            this.Ciudades = [
                'SELECCIONAR',
                 'ALTOS DE SUCRE',
                 'ARAYA',
                 'CARIACO',
                 'CARUPANO',
                 'CASANAY',
                 'CUMANA',
                 'CUMANACOA',
                 'EL MORRO PUERTO SANTO',
                 'EL PILAR',
                 'GUACA',
                 'GUIRIA',
                 'IRAPA',
                 'MARIGUITAR',
                 'RIO CARIBE',
                 'RIO GRANDE',
                 'SAN ANTONIO DEL GOLFO',
                 'SAN JOSE DE AEROCUAR',
                 'SANTA FE DE SUCRE',
                 'SAN VICENTE DE SUCRE',
                 'TUNAPUY',
                 'YAGUARAPARO',
                 'YOCO',
                 
            ]
            this.pr_estado = 'SUCRE'
            
        }
        if (ests == 'TÁCHIRA') {
            this.Ciudades = [
                'SELECCIONAR',
                'ABEJALES',
                'BOROTA',
                'COLON',
                'COLONCITO',
                'CORDERO',
                'EL AMPARO',
                'EL CANTON',
                'EL COBRE',
                'EL PINAL',
                'GUASDUALITO',
                'HATO DE LA VIRGEN',
                'INDEPENDENCIA',
                'LA FRIA',
                'LA FRITA',
                'LA PEDRERA',
                'LA TENDIDA',
                'LOBATERA',
                'MICHELENA',
                'PALMARITO',
                'PALMIRA',
                'PREGONERO',
                'QUENIQUEA',
                'RUBIO',
                'SAN ANTONIO DEL TACHIRA',
                'SAN CRISTOBAL',
                'SAN JOSECITO',
                'SAN JOSE DE BOLIVAR',
                'SAN JUAN DE COLON',
                'SAN PEDRO',
                'SANTA ANA TACHIRA',
                'SEBORUCO',
                'UMUQUENA',
                'UREÑA'
            ]
            this.pr_estado = 'TÁCHIRA'
            
        }
        if (ests == 'TRUJILLO') {
            this.Ciudades = [
                'SELECCIONAR',
                'ALTOS DE ESTANQUE',
                'BATATAL',
                'BETIJOQUE',
                'BOCONO',
                'BOLIVIA',
                'BURBUSAY',
                'CAMPO ELIAS',
                'CARACHE',
                'CHEJENDE',
                'CUICAS',
                'EL DIVIDIVE',
                'ESCUQUE',
                'ISNOTU',
                'JAJO',
                'LA CEIBA',
                'LA CEJITA',
                'LA MESA DE ESNUJAQUE',
                'LA PUERTA',
                'MONAY',
                'MONTE CARMELO',
                'MOTATAN',
                'NIQUITAO',
                'PAMPAN',
                'PAMPANITO',
                'PUNTO PALMARITO',
                'SABANA DE MENDOZA',
                'SABANA LIBRE',
                'SAN CRISTOBAL DE TORONDOY',
                'SAN LAZARO',
                'SAN MIGUEL',
                'SANTA ANA DE TRUJILLO',
                'SANTA APOLONIA DE MERIDA',
                'SANTA APOLONIA DE TRUJILLO',
                'SANTA ISABEL',
                'TOROCOCO',
                'TOSTOS',
                'TRUJILLO',
                'VALERA'
            ]
            this.pr_estado = 'TRUJILLO'
            
        }
        if (ests == 'VARGAS') {
            this.Ciudades = [
                'SELECCIONAR',
                'CARABALLEDA',
                'CARAYACA',
                'CATIA LA MAR',
                'MACUTO',
                'NAIGUATA'                
            ]
            this.pr_estado = 'VARGAS'
            
        }
        if (ests == 'YARACUY') {
            this.Ciudades = [
                'SELECCIONAR',
                'AROA',
                'BORAURE',
                'CAMPO ELIAS DE YARACUY',
                'CHIVACOA',
                'COCOROTE',
                'FARRIAR',
                'GUAMA',
                'MARIN',
                'NIRGUA',
                'SABANA DE PARRA',
                'SALOM',
                'SAN FELIPE',
                'SAN PABLO DE YARACUY',
                'URACHICHE',
                'YARITAGUA',
                'YUMARE'                
            ]
            this.pr_estado = 'YARACUY'
            
        }
        if (ests == 'ZULIA') {
            this.Ciudades = [
                'SELECCIONAR',
                'BACHAQUERO',
                'BOBURES',
                'CABIMAS',
                'CAMPO MARA',
                'CARRASQUERO',
                'CHIQUINQUIERA',
                'CIUDAD OJEDA',
                'EL BATEY',
                'EL CARMELO',
                'EL GUAYABO',
                'EL MENE',
                'EL VENADO',
                'ENCONTRADOS',
                'ISLA DE TOAS',
                'LAGUNILLAS DEL ZULIA',
                'LA PAZ',
                'MACHIQUES',
                'MARACAIBO',
                'MENE GRANDE',
                'PALMAREJO',
                'PUERTOS DE ALTAGRACIA',
                'PUNTA GORDA',
                'SAN JOSE DE PERIJA',
                'SAN LORENZO',
                'SAN RAFAEL DE MACHIQUES',
                'SANTA CRUZ DE MARA',
                'SANTA RITA',
                'SAN TIMOTEO',
                'SINAMAICA',
                'TIA JUANA',
                'VILLA DEL ROSARIO'
            ]
            this.pr_estado = 'ZULIA'
            
        }
        },
    }
}
</script>

<style>
.inputTxts {
    background-color: #fff;
    height: 35px;
    padding: 5px 10px;
    line-height: normal;
    color: #333;
    border: solid #a6a6a6 1px;
    border-top-color: #949494;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(255,255,255,.5), 0 1px 0 rgba(0,0,0,.07) inset;
    outline: 0;
    font-weight: 500;
    text-align: center;
    width: 90%;
}
.inputTxts:focus {
    border-color: #00aeff;
    box-shadow: 0 0 3px 2px #00aeff77;
}
</style>